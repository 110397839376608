import { axiosGet, axiosPost } from "./HttpBaseService";


export const AuthService = {
    userLoggerIn: false,
    Login: (body: any) => {
        return axiosPost(`${process.env.REACT_APP_API}/auth/userLogin`, body)
    },
    AutoLogin: () => {
        return axiosGet(`${process.env.REACT_APP_API}/auth/autoLogin`)
    },
    getUser: () => {
        return axiosGet(`${process.env.REACT_APP_API}/admin/getUser`)
    },
    logOut: () => {
        return axiosGet(`${process.env.REACT_APP_API}/auth/logout`)
    }


} 