import {
  Row,
  Col,
  Card,
  InputGroup,
  FormControl,
  Table,
  Nav,
  Accordion,
  Button,
  Form,
} from "react-bootstrap";
import { topicService } from "../../services/topicService";
import { useEffect, useState } from "react";
import preloader from "./../../assets/images/CC-Loaderfinal.gif";
import moment from "moment";
import * as filestack from "filestack-js";
const client = filestack.init("Am6LcC4IkQgy8iFWpZnFLz");

export default function TopicEditor(props: any) {
  const [formStatus, setFormStatus] = useState(props.status);
  const [topicId, setTopicId] = useState(props._id);
  const [uploadStatus, setUploadStatus] = useState(false);
  const [uploadSubTopicStatus, setUploadSubTopicStatus] = useState(false);
  const [topicImage, setTopicImage] = useState("");
  const [type, setType] = useState(props.type);
  const [subTopicImage, setSubTopicImage] = useState("");
  const [name, setName] = useState(props.name);
  const [description, setDescription] = useState(props.description);
  const [imageUrl, setImageUrl] = useState(props.imageUrl);
  const [isloading, setIsLoading] = useState(false);
  const [subtopicList, setSubTopicList] = useState([
    ...props.sub_topics.map((res: any) => {
      return {
        _id: res._id,
        name: res.name,
        description: res.description,
        imageUrl: res.imageUrl,
      };
    }),
  ]);
  const addSubtopic = () => {
    setSubTopicList([
      ...subtopicList,
      {
        name: "",
        description: "",
        imageUrl: "",
      },
    ]);
    console.log(subtopicList);
  };
  const removeSubtopic = (index: any) => {
    let subList = [...subtopicList];
    subList.splice(index, 1);
    setSubTopicList(subList);
  };
  const submitHandler = async () => {
    console.log(subtopicList);
    let finalTopic: any = {
      name,
      description,
      imageUrl,
      type: type,
      sub_topics: subtopicList,
      typeId: "new",
    };
    let response;
    if (formStatus) {
      response = await topicService.createTopic(finalTopic);
      props.refreshState();
    } else if (!formStatus) {
      finalTopic["_id"] = topicId;
      response = await topicService.updateTopic(finalTopic);
      props.refreshState();
    }
  };

  const handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>,
    value: string
  ) => {
    if (value === "topic") {
      setUploadStatus(true);
    } else {
      setUploadSubTopicStatus(true);
    }
    const file = event.target.files?.[0];

    if (file) {
      try {
        const result = await client.upload(file);
        console.log("File uploaded:", result.url);
        // Process the uploaded file here
        if (value == "topic") {
          setTopicImage(result.url);
          setUploadStatus(false);
        } else {
          setSubTopicImage(result.url);
          setUploadSubTopicStatus(false);
        }
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    }
  };
  return (
    <Form>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>Topic Name*</Form.Label>
        <Form.Control
          type="email"
          placeholder="Enter topic name"
          value={name}
          onChange={(event) => {
            setName(event.target.value);
          }}
        />
        {/* <Form.Text className="text-muted">
                      We'll never share your email with anyone else.
                    </Form.Text> */}
      </Form.Group>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>Topic Description*</Form.Label>
        <Form.Control
          as="textarea"
          rows={3}
          value={description}
          onChange={(event) => {
            setDescription(event.target.value);
          }}
        />
      </Form.Group>

      <Form.Group>
        <Form.Label>
          Upload Topic Image ( * Please note that only jpeg or png file format
          is accepted for uploads. )
        </Form.Label>
        <Form.Control
          type="file"
          onChange={(event: any) => {
            handleFileUpload(event, "topic");
          }}
          size="lg"
          style={{
            width: "600px",
            margin: "20px 0px",
          }}
        />
        {uploadStatus && <span>Uploading...</span>}
        {topicImage}
      </Form.Group>
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>SubTopic</Accordion.Header>
          <Accordion.Body>
            <Form>
              {subtopicList.map((result, idx) => {
                return (
                  <div
                    style={{
                      border: "1px  solid #ddd",
                      borderRadius: "18px",
                      marginTop: "15px",
                      padding: "20px",
                    }}
                  >
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>SubTopic Name*</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter subtopic name"
                        defaultValue={result.name}
                        onChange={(event) => {
                          result.name = event.target.value;
                        }}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>SubTopic Description*</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        defaultValue={result.description}
                        onChange={(event) => {
                          result.description = event.target.value;
                        }}
                      />
                    </Form.Group>

                    <Form.Group>
                      <Form.Label>
                        Upload SubTopic Image ( * Please note that only jpeg or
                        png file format is accepted for uploads. )
                      </Form.Label>
                      <Form.Control
                        type="file"
                        onChange={(event: any) => {
                          handleFileUpload(event, "sub-topic");
                        }}
                        size="lg"
                        style={{
                          width: "600px",
                          margin: "20px 0px",
                        }}
                      />
                      {uploadSubTopicStatus && <span>Uploading...</span>}
                      {subTopicImage}
                    </Form.Group>
                    <div className="text-end">
                      {subtopicList.length - 1 == idx && (
                        <Button
                          variant="primary"
                          type="button"
                          className="btn"
                          onClick={addSubtopic}
                          style={{
                            margin: "20px 10px",
                            width: "130px",
                          }}
                        >
                          Add
                        </Button>
                      )}
                      {subtopicList.length != 1 && (
                        <Button
                          variant="primary"
                          type="button"
                          className="btn"
                          onClick={() => {
                            removeSubtopic(idx);
                          }}
                          style={{
                            margin: "20px",
                            width: "130px",
                          }}
                        >
                          Remove
                        </Button>
                      )}
                    </div>
                  </div>
                );
              })}
            </Form>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      {/* <Card
                    border="dark"
                    style={{ borderColor: "black", borderWidth: "5px" }}
                  >
                    <Card.Header>Sub Topics*</Card.Header>
                    <Card.Body>
                      <Form>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Topic Name*</Form.Label>
                          <Form.Control
                            type="email"
                            placeholder="Enter email"
                          />
                        </Form.Group>
                      </Form>
                    </Card.Body>
                  </Card> */}
      <Button
        variant="primary"
        type="button"
        style={{ margin: "20px", width: "130px" }}
        onClick={submitHandler}
      >
        Submit
      </Button>
    </Form>
  );
}
